import ReactDOM from "react-dom/client";
import {useEffect} from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './Home';
import About from './About';
import Contact from './Contact'
import SideBar from "./SideBar";
import Login from "./Login";
import './style.css';
import { DataProvider} from "./DataContext";
import Dashboard from "./Dashboard";
import Airtime from "./buyAirtime";
import Data from './buyData';
import Transactions from './transactions';
import Fund from "./Fund";
import Head from "./Head";
import Cookie from 'react-cookies'
import { ForgetPassword } from "./ForgetPassword";
import SetNewPassword from "./SetNewPassword";
import { Profile } from "./Profile";
import {Password} from './ChangePassword'
import { Agent } from "./Agent";
import {Whatsapp} from './Whatsapp';
import { WalletHistory } from "./WalletHistory";
import BuyElectricity from "./buyElectricity";
import Example from './newsAlert'
import ExamCard from "./buyExamCard";
import MobileApp from "./mobileApp";
import AirtimeToCash from "./airtimeToCash";
import Cables from "./buyCable";
import UserToken from "./api/userToken";
import Documentation from "./api/documentation";
import BuyDataCard from "./buyDataCard";
import MonnifyCheckout from "./components/monnifyCheckout";
import PrintDataPin from "./printDatapin";
import PrintAirtimePins from "./printAirtimePin";



function App() {
  const user = Cookie.load('user')
  useEffect(()=> console.clear(), []);

  return (
    <div className='app'>
      {/* <Header/> */}
      {/* {user && <Head/>} */}
    <DataProvider>
    <BrowserRouter className='app'>
      
      <Routes>
          <Route exact path='/' element={<Home />} />
          <Route exact path='/login' element={<Login />} />
          <Route exact path="/sidebar" element={<SideBar />} />
          <Route exact path="/dashboard" element={<Dashboard />} />
          <Route exact path="/buyAirtime" element={<Airtime />} />
          <Route exact path="/buyData" element={<Data />} />
          <Route exact path="/buyDataCard" element={<BuyDataCard />} />
          <Route exact path="/buyElectricity" element={<BuyElectricity />} />
          <Route exact path="/buyExamCard" element={<ExamCard />} />
          <Route exact path="/buyCable" element={<Cables />} />
          <Route exact path="/transactions" element={<Transactions />} />
          <Route exact path="/fund" element={<Fund />} />
          <Route exact path="/forget" element={<ForgetPassword />} />
          <Route exact path="/reset" element={<SetNewPassword />} />
          <Route exact path="/profile" element={<Profile />} />
          <Route exact path="/password" element={<Password />} />
          <Route exact path="/agent" element={<Agent />} />
          <Route exact path="/walletHistory" element={<WalletHistory />} />
          <Route exact path="about" element={<About />} />
          <Route exact path="contact" element={<Contact />} />
          <Route exact path="*" element={"Page Note Found"} />
          <Route exact path="news" element={<Example />} />
          <Route exact path="app" element={<MobileApp />} />
          <Route exact path="airtimetocash" element={<AirtimeToCash/>} />
          <Route exact path="userToken" element={<UserToken/>} />
          <Route exact path="documentation" element={<Documentation/>} />
          <Route exact path="/check-out" element={<MonnifyCheckout/>} />
          <Route exact path="/printDataCard" element={<PrintDataPin/>} />
          <Route exact path="/printAirtimeCard" element={<PrintAirtimePins/>} />

      </Routes>
      {/* <Footer/> */}
  </BrowserRouter>
  {/* <Whatsapp/> */}
  </DataProvider>
  {/* <Footer/> */}
  </div>
  );
}

export default App;
