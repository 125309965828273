import React from 'react'

const PrintDataPin = () => {
    const rPins = `1629846683,1647608852,1645792100,1626374248,1623837445,1684630124,1608135867,1689416875,1680042127,1645091269,1674931005,1694579284,1659206388,1638577012,1602683790,1668252978,1632589124,1636847496,1692937085,1645109176,1696212334,1630729687,1671524098,1687660241,1663403145,1657118646,1637642116,1675980136,1610642353,1632623751,1693085540,1633788207,1656715692,1659326753,1661765792,1631878241,1643006397,1648722010,1657160421,1656301494,1615320497,1612376150,1628846694,1687507691,1620167498,1694927208,1697961432,1612869135,1630948059,1679007621`;
        const pins = rPins.split(',');
    console.log(pins);
  return (
    <div className='row'>
        {
            pins.map((pin, i) => (
                <div className='col-3 mb-1 card'
        style={{ 
            // height: '50px',
            // backgroundColor: 'red'
         }}
        >
            <div 
            style={{ 
                maxHeighteight: '50px',
                margin: 0
             }}
            className='my-0 d-flex justify-content-evenly align-items-center'>
                <img className='mt-0' src='logo.png' width={30} height={25} />
                <p style={{ 
                    fontSize: 13,
                    fontWeight: 800
                 }} className='mt-2 text-center'> MTN DATA CARD {'500MB'} </p> 
                <img src='mtn.png' width={30} height={25} />
            </div>
            <div >
                <p style={{ 
                    fontSize: 18,
                    fontWeight: 700
                 }} className='my-0 text-center'> Pin: {pin} </p>
                <p style={{ 
                    fontSize: 13,
                    fontWeight: 600
                 }} className='my-0 text-center'> To Load. Dial: *347*119*pin# </p>
                <p style={{ 
                    fontSize: 13,
                    fontWeight: 600
                 }} className='my-0 text-center'> To Check Balance. Dial: *461*4# </p>
            </div>
            <div
            className=''
             style={{ 
                border: 1,
                borderStyle: 'groove',
                borderBottomColor: 'red',
                color: 'white',
                backgroundColor: 'black',
                maxHeight: '55px'
                }}
            >
            <p 
            style={{ 
                fontSize: 11,
                fontWeight: 900,
                
             }}
            className='my-0 text-center'> Powered by UXTECH </p>
             <p 
            style={{ 
                fontSize: 9,
                fontWeight: 900,
                
             }}
            className='my-0 text-center'> (UX-ELITE TECHNOLOGIES) </p>
           <span className='my-0 d-flex justify-content-evenly mb-0'
           style={{ 
            borderBottom: 1,
            borderBottomStyle: 'groove',
            borderBottomColor: 'red',
            alignItems: ''
            }}
           >
                <p style={{ 
                    fontSize: 10,
                    fontWeight: 600
                 }}> www.uxtech.ng </p>
                {/* <p 
                style={{ 
                    fontSize: 12,
                    fontWeight: 600
                 }}
                > 08138282448 </p> */}
           </span>
            </div>
        </div>
            ))
        }
    </div>
  )
}

export default PrintDataPin