// import {} from 'react-bootstrap/Footer'
import './style.css';
const Footer = () =>{

    return (
        <div className='footer' style={{ height: '50px', paddingTop: 8}}> 
            {/* <h1> Footer </h1> */}
        
       <p className='text-white' style={{ textAlign: 'left', marginLeft: 15,   }}> <i><b>Copyright &copy; UX-ELITE TECHNOLOGIES {new Date().getFullYear()} </b> </i></p>
        </div>
    )
} 

export default Footer