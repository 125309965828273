import React from 'react'

const PrintAirtimePins = () => {

    const rPins = `11597761046238744, 21895462992721547, 01135537196053193, 07420610754453923, 46158005566693065, 18016841588652369, 78845645951716547, 48602308792397916, 32308556396978099, 64827007834098408, 11595115301203012, 11597443414235484, 50632136690681213, 15807452674312435, 35158075624118267, 66219906477924820, 21897678150693402, 39589012109530730, 07174987946393237, 17710525433127744, 45695138988027198, 70127125477673300, 71644550409204812, 66001990709678820, 97453866689314522, 90050227150053746, 10004176248687613, 35151568418585505, 79891889788453919, 76910039636468744, 49715773272105820, 57864781248944435, 89521006543582730, 91789304437389526, 38902316232397526, 50634460636023731, 95336622035117402, 25873125349081540, 09320915608453173, 38339812729105436, 04834598301864457, 65377060573238547, 89526262272216577, 07174675636389684, 18019107462609914, 11595698824195436, 25396560992582228, 79895668626189547, 53246211911310812, 37915824714314435`;

    const pins = rPins.split(',');
    console.log(pins);
  return (
    <div className='row'>
        {
            pins.map((pin) => (
                <div className='col-3 mb-1 card'
        style={{ 
            // height: '50px',
            // backgroundColor: 'red'
         }}
        >
            <span 
            style={{ 
                maxHeighteight: '50px',
                margin: 0
             }}
            className='my-0 d-flex justify-content-evenly align-items-center'>
                <img className='mt-0' src='logo.png' width={30} height={25} />
                <p style={{ 
                    fontSize: 13,
                    fontWeight: 800
                 }} className=''> MTN AIRTIME </p>
                <img src='mtn.png' width={30} height={25} />
            </span>
            <p
            style={{ 
                fontSize: 16,
                fontWeight:700
             }}
            className='text-center my-0'>₦500</p>
            <div >
                <p style={{ 
                    fontSize: 13,
                    fontWeight: 600
                 }} className='my-0 text-center'> Pin: {pin} </p>
                <p style={{ 
                    fontSize: 13,
                    fontWeight: 600
                 }} className='my-0 text-center'> To Load. Dial: *311*pin# </p>
                <p style={{ 
                    fontSize: 13,
                    fontWeight: 600
                 }} className='my-0 text-center'> To Check Balance. Dial: *310# </p>
            </div>
            <div
            className=''
             style={{ 
                border: 1,
                borderStyle: 'groove',
                borderBottomColor: 'red',
                color: 'white',
                backgroundColor: 'black',
                maxHeight: '55px'
                }}
            >
            <p 
            style={{ 
                fontSize: 13,
                fontWeight: 900,
                
             }}
            className='my-0 text-center'> Powered by UXTECH </p>
             <p 
            style={{ 
                fontSize: 10,
                fontWeight: 900,
                
             }}
            className='my-0 text-center'> (UX-ELITE TECHNOLOGIES) </p>
           <span className='my-0 d-flex justify-content-evenly mb-0'
           style={{ 
            borderBottom: 1,
            borderBottomStyle: 'groove',
            borderBottomColor: 'red',
            alignItems: ''
            }}
           >
                <p style={{ 
                    fontSize: 12,
                    fontWeight: 600
                 }}> www.uxtech.ng </p>
                {/* <p 
                style={{ 
                    fontSize: 12,
                    fontWeight: 600
                 }}
                > 08138282448 </p> */}
           </span>
            </div>
        </div>
            ))
        }
    </div>
  )
}

export default PrintAirtimePins